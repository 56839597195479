
                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                





















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.airport-id {
  color: $sma-blue;
  font-size: 1rem;
}

.content {
  padding: 2rem;
}

.email {
  color: $sma-blue;
  cursor: pointer;
}

.image {
  background-color: $sma-near-white;
}

.buttons {
  display: flex;
  align-items: center;
  margin: 1rem 0;

  button {
    cursor: pointer;
    font-size: 0.9em;
    color: $sma-blue;
    border: 0;
    background: transparent;
  }

  .icon {
    margin-right: 0.3rem;
  }

  .favorite,
  .copytoclipboard,
  .delete,
  .disable {
    margin-left: 1rem;

    @media only screen and (max-width: $breakpoint-sm) {
      margin-left: 0;
    }
  }
}

.top {
  display: flex;
  flex-direction: column;
}

.description {
  font-size: 0.875rem;
  height: 100%;
  margin-top: 2em;

  & textarea {
    width: 100%;
    height: 100%;
    border: 1px solid $sma-gray;
  }
}

/*
.button {
  align-self: center;
  color: $sma-yellow;
  background-color: $sma-white;
  border: 1px solid $sma-yellow;
  border-radius: 10rem;
  padding: 0.5rem 2rem;
  margin-top: 3rem;
}
*/

.price {
  width: 100px;
  border-bottom: 1px solid $sma-gray;
  color: $sma-gray;
}

.pair {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
  padding: 0.25rem;
  flex-wrap: wrap;
}

.name {
  font-weight: 600;
  margin-right: 0.5em;
  min-width: 220px;
  text-align: right;

  @media screen and (max-width: $breakpoint-sm) {
    min-width: 100%;
    text-align: left;
  }
}

.value {
  flex: 1;
  color: $sma-gray;

  & input {
    width: 100%;
    border-bottom: 1px solid $sma-gray;
    color: $sma-gray;
  }
}

.split .value {
  width: 100%;
}

.slider-outer {
  position: relative;
  max-width: 500px;
  max-height: 500px;
}

.slide-pre {
  position: absolute;
  left: 20px;
  top: 50%;
  z-index: 2;
  color: $sma-white;
}

.slide-next {
  position: absolute;
  right: 20px;
  top: 50%;
  z-index: 2;
  color: $sma-white;
}

.airport-select {
  margin-top: -6px !important;
}

.fail {
  font-size: 0.875rem;
  color: red;
  margin-left: 1rem;
}

[default-media] {
  box-shadow: 0 0 20px 10px $sma-blue;
}

.error {
  display: flex;
  flex-direction: column;
}

.drop-text {
  margin-top: 1rem;
  font-size: 0.9rem;
  width: 100%;
  text-align: center;
}

.tail-number {
  margin-top: 0;
}
